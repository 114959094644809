import firebase from "../config/firebase";
import Log from '../components/log'
import "firebase/auth";
import "firebase/firestore";
import Cripto from '../components/cripto'
import AlergiaModel from '../model/alergiaModel';
import { store } from '../../src/index.js'
import { storage } from "../config/firebasestorage";
import { replaceEspace } from "../utils/utils";

export const ALERGIAS_LISTAR = "ALERGIAS_LISTAR";
export const alergiasListar = (alergias) => ({
    type: ALERGIAS_LISTAR,
    alergias: alergias,
});

export const ALERGIA_SET_CURRENT = 'ALERGIA_SET_CURRENT';
const alergiaSetCurrent = alergia => ({
    type: ALERGIA_SET_CURRENT,
    alergia
});

export const ALERGIA_SET_FIELD = 'ALERGIA_SET_FIELD';
export const alergiaSetField = (field, value) => {
    return {
        type: ALERGIA_SET_FIELD,
        field,
        value,
    }
}


export const alergiaSetAtual = (id) => dispatch => {
    if (id == null) {
        console.log(AlergiaModel.Clear())
        var alergia = AlergiaModel.Clear()
        console.log(alergia)
        dispatch(alergiaSetCurrent(alergia));
    } else {
        var currentValue = store.getState().alergias;
        let alergia = '';
        currentValue.map((item) => {
            if (item.id === id) {
                alergia = item;
            }
        });
        dispatch(alergiaSetCurrent(alergia));
    }
}

export const ExcluirAlergia = async (idCliente, idItem, callbackFinishedDelete) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('alergias')
                    .doc(idItem)
                    .delete()
                    .then((success) => {
                        callbackFinishedDelete();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('alergias')
                    .doc(idItem)
                    .delete()
                    .then((success) => {
                        callbackFinishedDelete();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'ExcluirAlergias', ex.message, "")
    }
}

export const cadastrarAlergiaSemAnexo = async (idCliente, callbackFinishedUpload,) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        var alergia = store.getState().alergia;
        var alergiaModel = AlergiaModel.ToDB(alergia)
        alergiaModel.observacao = replaceEspace(alergiaModel.observacao);
        alergiaModel.nome = replaceEspace(alergiaModel.nome);
        return await new Promise((resolve, reject) => {
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('alergias')
                    .add(alergiaModel)
                    .then((success) => {
                        callbackFinishedUpload();
                        resolve('sucesso');
                    }).catch((error) => {
                        console.log(error)
                        reject('error')
                    })
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('alergias')
                    .add(alergiaModel)
                    .then((success) => {
                        callbackFinishedUpload();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            }
        });
    } catch (ex) {
        console.log(ex)
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarAlergiaSemAnexo', ex.message, "")
    }
}


export const cadastrarAlergiaComAnexo = async (idCliente, pdf, callbackFinishedUpload) => {
    try {
        var alergia = store.getState().alergia;
        var alergiaModel = AlergiaModel.ToDB(alergia)
        alergiaModel.observacao = replaceEspace(alergiaModel.observacao);
        alergiaModel.nome = replaceEspace(alergiaModel.nome);
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (pdf.length > 0) {
                const uploadMedicamento = storage.ref(`alergias/${idTitular}/${pdf[0].name}`)
                    .put(pdf[0]);
                uploadMedicamento.then((success) => {
                    storage.ref(`alergias/${idTitular}/`)
                        .child(pdf[0].name)
                        .getDownloadURL()
                        .then(async (url) => {
                            var tipo = 0;
                            if (pdf[0].name.toString().toLowerCase().includes("pdf")) {
                                tipo = 1
                            }
                            alergiaModel.url = url
                            if (idTitular == idCliente) {

                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('alergias')
                                    .add(alergiaModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            } else {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('dependentes')
                                    .doc(idCliente)
                                    .collection('alergias')
                                    .add(alergiaModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            }
                        }).catch((error) => {
                            console.log('cadastrarAlergiaComAnexo ERROR1: ' + error.message);
                            Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarAlergiaComAnexo', error.message, "")
                            reject("error");
                        });
                })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarAlergiaComAnexo', ex.message, "")
    }
}

export const atualizarAlergiaComAnexo = async (idCliente, idItem, pdf, callbackFinishedUpload) => {
    try {
        var alergia = store.getState().alergia;
        var alergiaModel = AlergiaModel.ToDB(alergia)
        alergiaModel.observacao = replaceEspace(alergiaModel.observacao);
        alergiaModel.nome = replaceEspace(alergiaModel.nome);
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (pdf.length > 0) {
                const uploadMedicamento = storage.ref(`alergias/${idTitular}/${pdf[0].name}`)
                    .put(pdf[0]);
                uploadMedicamento.then((success) => {
                    storage.ref(`alergias/${idTitular}/`)
                        .child(pdf[0].name)
                        .getDownloadURL()
                        .then(async (url) => {
                            var tipo = 0;
                            if (pdf[0].name.toString().toLowerCase().includes("pdf")) {
                                tipo = 1
                            }
                            alergiaModel.url = url;
                            if (idTitular == idCliente) {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('alergias')
                                    .doc(idItem)
                                    .update(alergiaModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            } else {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('dependentes')
                                    .doc(idCliente)
                                    .collection('alergias')
                                    .doc(idItem)
                                    .update(alergiaModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            }
                        }).catch((error) => {
                            console.log('cadastrarAlergiaComAnexo ERROR1: ' + error.message);
                            Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarAlergiaComAnexo', error.message, "")
                            reject("error");
                        });
                })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarAlergiaComAnexo', ex.message, "")
    }
}

export const atualizarAlergia = async (idCliente, idItem, callbackFinishedUpload) => {
    try {
        return await new Promise((resolve, reject) => {
            var alergia = store.getState().alergia;
            var alergiaModel = AlergiaModel.ToDB(alergia)
            alergiaModel.observacao = replaceEspace(alergiaModel.observacao);
            alergiaModel.nome = replaceEspace(alergiaModel.nome);
            var idTitular = Cripto.DecryptedStorage('idPaciente');
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idCliente)
                    .collection('alergias')
                    .doc(idItem)
                    .update(alergiaModel).then((success) => {
                        resolve('sucesso')
                        callbackFinishedUpload()
                    }
                    ).catch((error) => {
                        reject('error')
                    });
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('alergias')
                    .doc(idItem)
                    .update(alergiaModel).then((success) => {
                        resolve('sucesso')
                        callbackFinishedUpload()
                    }
                    ).catch((error) => {
                        reject('error')
                    });;
            }
        });
    } catch (ex) {
        console.log(ex)
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atualizarMedicamento', ex.message, "")
    }
}

export const getAlergiasCliente = (idCliente) => (dispatch) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        let alergiasList = [];
        if (idTitular == idCliente) {
            firebase
                .firestore()
                .collection("pacientes")
                .doc(idCliente)
                .collection("alergias")
                .get()
                .then((alergias) => {
                    if (alergias.docs.length > 0) {
                        alergias.docs.map((doc) => {
                            let medicamento = AlergiaModel.FromDB(doc.id, doc.data(),)
                            alergiasList.push(medicamento);
                        });
                        console.log(alergiasList)
                        Cripto.EncryptedStorage("alergias", JSON.stringify(alergiasList));
                        dispatch(alergiasListar(alergiasList));
                    } else {
                        dispatch(alergiasListar('sem alergias'));
                    }
                })
            //return Cripto.DecryptedStorage('atendimento_documentos');
        } else {
            firebase
                .firestore()
                .collection("pacientes")
                .doc(idTitular)
                .collection("dependentes")
                .doc(idCliente)
                .collection("alergias")
                .get()
                .then((alergias) => {
                    if (alergias.docs.length > 0) {
                        alergias.docs.map((doc) => {
                            let medicamento = AlergiaModel.FromDB(doc.id, doc.data(),)
                            alergiasList.push(medicamento);
                        });
                        Cripto.EncryptedStorage("alergias", JSON.stringify(alergiasList));
                        dispatch(alergiasListar(alergiasList));
                    } else {
                        dispatch(alergiasListar('sem alergias'));
                    }
                })
        }
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getMedicamentos', ex.message, "")
    }
};