import React from 'react';

const Login = React.lazy(() => import('./views/Login'));
const Logoff = React.lazy(() => import('./views/Logoff'));
const RedefinirSenha = React.lazy(()=> import('./views/Redefinirsenha'));

const CriarNovoCliente = React.lazy(()=> import('./views/ValidarUsuario/CriarNovoCliente.jsx'));

const ValidarNovoUsuario = React.lazy(()=> import('./views/ValidarUsuario/ValidarNovoUsuario.jsx'));

const route = [
   
//-------------NOSSOS ITENS SEM MENU !!! -------------- //
    { path: '/login', exact: true, name: 'Login', component: Login },
    { path: '/logoff', exact: true, name: 'Logoff', component: Logoff },
    { path: '/redefinirsenha', exact: true, name: "Redefinir Senha", component: RedefinirSenha},
    { path: '/validarusuario', exact: true, name: "Validar Usuário", component: ValidarNovoUsuario},
    { path: '/validarusuario/criarusuario', exact: true, name: "Criar Usuário", component: CriarNovoCliente}
];

export default route;