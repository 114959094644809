import CryptoJS from 'crypto-js';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import firebase from '../config/firebase';
import 'firebase/auth';


var keyAccess = "OLAMED_SRC";
class Cripto{
    static running  = false;
    constructor() {
        keyAccess = Base64.stringify(hmacSHA512('uid', keyAccess));

        keyAccess = localStorage.getItem(keyAccess);
    }

    /*
    static EncryptedStorage(keyStorage, str){
        if (isNaN(str)) {
            localStorage.setItem(keyStorage, str.toString());
        }else{
            localStorage.setItem(keyStorage,str.toString());
        }
    }
    
    static DecryptedStorage(keyStorage){
        localStorage.getItem(keyStorage);
    }*/

    
    static EncryptedStorage(keyStorage, str){
        try{
            var criptKey = '';
            //criptKey = CryptoJS.MD5(keyStorage, keyAccess).toString(); //criptografando a chave tb! wuauuuu! :-)
            criptKey =  Base64.stringify(hmacSHA512(keyStorage, keyAccess));

            if (isNaN(str)) {
                localStorage.setItem(criptKey, CryptoJS.AES.encrypt(str, keyAccess).toString());
            }else{
                localStorage.setItem(criptKey, CryptoJS.AES.encrypt(str.toString(), keyAccess).toString());
            }
            /*
            if(keyStorage == 'uid'){
                localStorage.setItem('uid', str);
            }*/
        }
        catch(ex){
            console.log("Erro: EncryptedStorage" + ex.message)
        }
    }

    static DecryptedStorage(keyStorage){
        try{
            var criptKey = '';
            //criptKey = CryptoJS.MD5(keyStorage, keyAccess).toString(); //criptografando a chave tb! wuauuuu! :-)
            criptKey =  Base64.stringify(hmacSHA512(keyStorage, keyAccess));

            while(Cripto.running){
                console.log('Concorrencia aqui!')
            }
            Cripto.running = true;


            var resultSorage = localStorage.getItem(criptKey);
            
            if(resultSorage != undefined && resultSorage != null){
            
                Cripto.running = false;
                return CryptoJS.AES.decrypt(localStorage.getItem(criptKey), keyAccess).toString(CryptoJS.enc.Utf8);
            }
        
            Cripto.running = false;
            return null;
        }
        catch(ex){
            console.log("Erro: DecryptedStorage" + ex.message)
        }
    }
}

export default Cripto;