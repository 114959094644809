import { MEDICAMENTOS_LISTAR } from '../actions';


///Listar Alergias, Cirurgias, Exames e Medicamentos
const INITIAL_STATE =
{
    medicamentos: '',
}

export default function medicamentoListarReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case MEDICAMENTOS_LISTAR:
            return action.medicamentos
        default:
            return state;
    }
}