import firebase from "../config/firebase";
import Log from '../components/log'
import "firebase/auth";
import "firebase/firestore";
import Cripto from '../components/cripto'
import CirurgiaModel from '../model/alergiaModel';
import { store } from '../../src/index.js'
import { storage } from "../config/firebasestorage";
import { replaceEspace } from "../utils/utils";

export const CIRURGIAS_LISTAR = "CIRURGIAS_LISTAR";
export const cirurgiasListar = (cirurgias) => ({
    type: CIRURGIAS_LISTAR,
    cirurgias: cirurgias,
});

export const CIRURGIA_SET_CURRENT = 'CIRURGIA_SET_CURRENT';
const cirurgiaSetCurrent = cirurgia => ({
    type: CIRURGIA_SET_CURRENT,
    cirurgia
});

export const CIRURGIA_SET_FIELD = 'CIRURGIA_SET_FIELD';
export const cirurgiaSetField = (field, value) => {
    return {
        type: CIRURGIA_SET_FIELD,
        field,
        value,
    }
}


export const cirurgiaSetAtual = (id) => dispatch => {
    if (id == null) {
        var cirurgia = CirurgiaModel.Clear()
        dispatch(cirurgiaSetCurrent(cirurgia));
    } else {
        var currentValue = store.getState().cirurgias;
        let cirurgia = '';
        currentValue.map((item) => {
            if (item.id === id) {
                cirurgia = item;
            }
        });
        dispatch(cirurgiaSetCurrent(cirurgia));
    }
}

export const ExcluirCirurgia = async (idCliente, idItem, callbackFinishedDelete) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('cirurgias')
                    .doc(idItem)
                    .delete()
                    .then((success) => {
                        callbackFinishedDelete();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('cirurgias')
                    .doc(idItem)
                    .delete()
                    .then((success) => {
                        callbackFinishedDelete();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'ExcluirCirurgias', ex.message, "")
    }
}

export const cadastrarCirurgiaSemAnexo = async (idCliente, callbackFinishedUpload,) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        var cirurgia = store.getState().cirurgia;
        var cirurgiaModel = CirurgiaModel.ToDB(cirurgia)
        cirurgiaModel.observacao = replaceEspace(cirurgiaModel.observacao);
        cirurgiaModel.nome = replaceEspace(cirurgiaModel.nome);
        return await new Promise((resolve, reject) => {
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('cirurgias')
                    .add(cirurgiaModel)
                    .then((success) => {
                        callbackFinishedUpload();
                        resolve('sucesso');
                    }).catch((error) => {
                        console.log(error)
                        reject('error')
                    })
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('cirurgias')
                    .add(cirurgiaModel)
                    .then((success) => {
                        callbackFinishedUpload();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            }
        });
    } catch (ex) {
        console.log(ex)
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarCirurgiaSemAnexo', ex.message, "")
    }
}


export const cadastrarCirurgiaComAnexo = async (idCliente, pdf, callbackFinishedUpload) => {
    try {
        var cirurgia = store.getState().cirurgia;
        var cirurgiaModel = CirurgiaModel.ToDB(cirurgia)
        cirurgiaModel.observacao = replaceEspace(cirurgiaModel.observacao);
        cirurgiaModel.nome = replaceEspace(cirurgiaModel.nome);
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (pdf.length > 0) {
                const uploadMedicamento = storage.ref(`cirurgias/${idTitular}/${pdf[0].name}`)
                    .put(pdf[0]);
                uploadMedicamento.then((success) => {
                    storage.ref(`cirurgias/${idTitular}/`)
                        .child(pdf[0].name)
                        .getDownloadURL()
                        .then(async (url) => {
                            var tipo = 0;

                            if (pdf[0].name.toString().toLowerCase().includes("pdf")) {
                                tipo = 1
                            }
                            cirurgiaModel.url = url;
                            if (idTitular == idCliente) {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('cirurgias')
                                    .add(cirurgiaModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            } else {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('dependentes')
                                    .doc(idCliente)
                                    .collection('cirurgias')
                                    .add(cirurgiaModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            }
                        }).catch((error) => {
                            console.log('cadastrarCirurgiaComAnexo ERROR1: ' + error.message);
                            Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarCirurgiaComAnexo', error.message, "")
                            reject("error");
                        });
                })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarCirurgiaComAnexo', ex.message, "")
    }
}

export const atualizarCirurgiaComAnexo = async (idCliente, idItem, pdf, callbackFinishedUpload) => {
    try {
        var cirurgia = store.getState().cirurgia;
        var cirurgiaModel = CirurgiaModel.ToDB(cirurgia)
        cirurgiaModel.nome = replaceEspace(cirurgiaModel.nome)
        cirurgiaModel.observacao = replaceEspace(cirurgiaModel.observacao)
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (pdf.length > 0) {
                const uploadMedicamento = storage.ref(`cirurgias/${idTitular}/${pdf[0].name}`)
                    .put(pdf[0]);
                uploadMedicamento.then((success) => {
                    storage.ref(`cirurgias/${idTitular}/`)
                        .child(pdf[0].name)
                        .getDownloadURL()
                        .then(async (url) => {
                            var tipo = 0;
                            if (pdf[0].name.toString().toLowerCase().includes("pdf")) {
                                tipo = 1
                            }
                            cirurgiaModel.url = url;
                            if (idTitular == idCliente) {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('cirurgias')
                                    .doc(idItem)
                                    .update(cirurgiaModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            } else {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('dependentes')
                                    .doc(idCliente)
                                    .collection('cirurgias')
                                    .doc(idItem)
                                    .update(cirurgiaModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            }
                        }).catch((error) => {
                            console.log('atualizarCirurgiaComAnexo ERROR1: ' + error.message);
                            Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atualizarCirurgiaComAnexo', error.message, "")
                            reject("error");
                        });
                })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atualizarCirurgiaComAnexo', ex.message, "")
    }
}

export const atualizarCirurgia = async (idCliente, idItem, callbackFinishedUpload) => {
    try {
        return await new Promise((resolve, reject) => {
            var cirurgia = store.getState().cirurgia;
            var cirurgiaModel = CirurgiaModel.ToDB(cirurgia)
            cirurgiaModel.nome = replaceEspace(cirurgiaModel.nome)
            cirurgiaModel.observacao = replaceEspace(cirurgiaModel.observacao)
            var idTitular = Cripto.DecryptedStorage('idPaciente');
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idCliente)
                    .collection('cirurgias')
                    .doc(idItem)
                    .update(
                        cirurgiaModel
                    ).then((success) => {
                        resolve('sucesso')
                        callbackFinishedUpload()
                    }
                    ).catch((error) => {
                        reject('error')
                    });
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('cirurgias')
                    .doc(idItem)
                    .update(
                        cirurgiaModel
                    ).then((success) => {
                        resolve('sucesso')
                        callbackFinishedUpload()
                    }
                    ).catch((error) => {
                        reject('error')
                    });;
            }
        });
    } catch (ex) {
        console.log(ex)
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atualizarCirurgia', ex.message, "")
    }
}

export const getCirurgiasCliente = (idCliente) => (dispatch) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        let cirurgiasList = [];
        if (idTitular == idCliente) {
            firebase
                .firestore()
                .collection("pacientes")
                .doc(idCliente)
                .collection("cirurgias")
                .get()
                .then((cirurgias) => {
                    if (cirurgias.docs.length > 0) {
                        cirurgias.docs.map((doc) => {
                            let cirurgia = CirurgiaModel.FromDB(doc.id, doc.data(),)
                            cirurgiasList.push(cirurgia);
                        });
                        console.log(cirurgiasList)
                        Cripto.EncryptedStorage("cirurgias", JSON.stringify(cirurgiasList));
                        dispatch(cirurgiasListar(cirurgiasList));
                    } else {
                        dispatch(cirurgiasListar("sem cirurgias"));
                    }
                })
            //return Cripto.DecryptedStorage('atendimento_documentos');
        } else {
            firebase
                .firestore()
                .collection("pacientes")
                .doc(idTitular)
                .collection("dependentes")
                .doc(idCliente)
                .collection("cirurgias")
                .get()
                .then((cirurgias) => {
                    if (cirurgias.docs.length > 0) {
                        cirurgias.docs.map((doc) => {
                            let cirurgia = CirurgiaModel.FromDB(doc.id, doc.data(),)
                            cirurgiasList.push(cirurgia);
                        });
                        Cripto.EncryptedStorage("cirurgias", JSON.stringify(cirurgiasList));
                        dispatch(cirurgiasListar(cirurgiasList));
                    } else {
                        dispatch(cirurgiasListar("sem cirurgias"));
                    }
                })
        }
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getCirurgias', ex.message, "")
    }
};