import firebase from "../config/firebase";
import Log from '../components/log'
import "firebase/auth";
import "firebase/firestore";
import Cripto from '../components/cripto'
import MedicamentoModel from '../model/medicamentoModel';
import { store } from '../../src/index.js'
import { storage } from "../config/firebasestorage";
import { replaceEspace } from "../utils/utils";

export const MEDICAMENTOS_LISTAR = "MEDICAMENTOS_LISTAR";
export const medicamentosListar = (medicamentos) => ({
    type: MEDICAMENTOS_LISTAR,
    medicamentos: medicamentos,
});

export const MEDICAMENTO_SET_CURRENT = 'MEDICAMENTO_SET_CURRENT';
const medicamentoSetCurrent = medicamento => ({
    type: MEDICAMENTO_SET_CURRENT,
    medicamento
});

export const MEDICAMENTO_SET_FIELD = 'MEDICAMENTO_SET_FIELD';
export const medicamentoSetField = (field, value) => {
    return {
        type: MEDICAMENTO_SET_FIELD,
        field,
        value,
    }
}


export const medicamentoSetAtual = (id) => dispatch => {
    if (id == null) {
        var medicamento = MedicamentoModel.Clear()
        dispatch(medicamentoSetCurrent(medicamento));
    } else {
        var currentValue = store.getState().medicamentos;
        let medicamento = '';
        currentValue.map((item) => {
            if (item.id === id) {
                medicamento = item;
            }
        });
        dispatch(medicamentoSetCurrent(medicamento));
    }
}

export const excluirMedicamento = async (idCliente, idItem, callbackFinishedDelete) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('medicamentos')
                    .doc(idItem)
                    .delete()
                    .then((success) => {
                        callbackFinishedDelete();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('medicamentos')
                    .doc(idItem)
                    .delete()
                    .then((success) => {
                        callbackFinishedDelete();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'ExcluirMedicamento', ex.message, "")
    }
}

export const cadastrarMedicamentoSemAnexo = async (idCliente, callbackFinishedUpload,) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        var medicamento = store.getState().medicamento;
        var medicamentoModel = MedicamentoModel.ToDB(medicamento)
        medicamentoModel.observacao = replaceEspace(medicamentoModel.observacao);
        medicamentoModel.nome = replaceEspace(medicamentoModel.nome);
        medicamentoModel.dose = replaceEspace(medicamentoModel.dose);
        return await new Promise((resolve, reject) => {
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('medicamentos')
                    .add(medicamentoModel)
                    .then((success) => {
                        callbackFinishedUpload();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('medicamentos')
                    .add(medicamentoModel)
                    .then((success) => {
                        callbackFinishedUpload();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            }
        });
    } catch (ex) {
        console.log(ex)
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarMedicamentoSemAnexo', ex.message, "")
    }
}

export const atualizarMedicamentoComAnexo = async (idCliente, idItem, pdf, callbackFinishedUpload) => {
    try {
        var medicamento = store.getState().medicamento;
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (pdf.length > 0) {
                var medicamentoModel = MedicamentoModel.ToDB(medicamento)
                medicamentoModel.observacao = replaceEspace(medicamentoModel.observacao);
                medicamentoModel.nome = replaceEspace(medicamentoModel.nome);
                medicamentoModel.dose = replaceEspace(medicamentoModel.dose);
                const uploadMedicamento = storage.ref(`medicamentos/${idTitular}/${pdf[0].name}`).put(pdf[0]);
                uploadMedicamento.then((success) => {
                    storage.ref(`medicamentos/${idTitular}/`)
                        .child(pdf[0].name)
                        .getDownloadURL()
                        .then(async (url) => {
                            ///setar a url
                            var tipo = 0;
                            if (pdf[0].name.toString().toLowerCase().includes("pdf")) {
                                tipo = 1
                            }
                            medicamentoModel.url = url;
                            if (idTitular == idCliente) {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('medicamentos')
                                    .doc(idItem)
                                    .update(medicamentoModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            } else {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('dependentes')
                                    .doc(idCliente)
                                    .collection('medicamentos')
                                    .doc(idItem)
                                    .update(medicamentoModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            }
                        }).catch((error) => {
                            console.log('uploadMedicamentosToFirebase1 ERROR1: ' + error.message);
                            Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'uploadMedicamentosToFirebase1', error.message, "")
                            reject("error");
                        });
                })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarMedicamentoComAnexo', ex.message, "")
    }
}

export const cadastrarMedicamentoComAnexo = async (idCliente, pdf, callbackFinishedUpload) => {
    try {
        var medicamento = store.getState().medicamento;
        var medicamentoModel = MedicamentoModel.ToDB(medicamento)
        medicamentoModel.observacao = replaceEspace(medicamentoModel.observacao);
        medicamentoModel.nome = replaceEspace(medicamentoModel.nome);
        medicamentoModel.dose = replaceEspace(medicamentoModel.dose);
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (pdf.length > 0) {

                const uploadMedicamento = storage.ref(`medicamentos/${idTitular}/${pdf[0].name}`).put(pdf[0]);
                uploadMedicamento.then((success) => {
                    storage.ref(`medicamentos/${idTitular}/`)
                        .child(pdf[0].name)
                        .getDownloadURL()
                        .then(async (url) => {
                            ///setar a url
                            var tipo = 0;
                            if (pdf[0].name.toString().toLowerCase().includes("pdf")) {
                                tipo = 1
                            }
                            medicamentoModel.url = url;
                            if (idTitular == idCliente) {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('medicamentos')
                                    .add(medicamentoModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            } else {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('dependentes')
                                    .doc(idCliente)
                                    .collection('medicamentos')
                                    .add(medicamentoModel)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            }
                        }).catch((error) => {
                            console.log('uploadMedicamentosToFirebase1 ERROR1: ' + error.message);
                            Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'uploadMedicamentosToFirebase1', error.message, "")
                            reject("error");
                        });
                })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarMedicamentoComAnexo', ex.message, "")
    }
}


export const atualizarMedicamento = async (idCliente, idItem, callbackFinishedUpload) => {
    try {
        return await new Promise((resolve, reject) => {
            var medicamento = store.getState().medicamento;
            var idTitular = Cripto.DecryptedStorage('idPaciente');
            var medicamentoModel = MedicamentoModel.ToDB(medicamento)
            medicamentoModel.observacao = replaceEspace(medicamentoModel.observacao);
            medicamentoModel.nome = replaceEspace(medicamentoModel.nome);
            medicamentoModel.dose = replaceEspace(medicamentoModel.dose);
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idCliente)
                    .collection('medicamentos')
                    .doc(idItem)
                    .update(
                        medicamentoModel
                    ).then((success) => {
                        resolve('sucesso')
                        callbackFinishedUpload()
                    }
                    ).catch((error) => {
                        reject('error')
                    });
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('medicamentos')
                    .doc(idItem)
                    .update(
                        medicamentoModel
                    ).then((success) => {
                        resolve('sucesso')
                        callbackFinishedUpload()
                    }
                    ).catch((error) => {
                        reject('error')
                    });;
            }
        });
    } catch (ex) {
        console.log(ex)
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atualizarMedicamento', ex.message, "")
    }
}

export const getMedicamentosCliente = (idCliente) => (dispatch) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        if (idTitular == idCliente) {
            let medicamentosList = [];
            firebase
                .firestore()
                .collection("pacientes")
                .doc(idCliente)
                .collection("medicamentos")
                .get()
                .then((medicamentos) => {
                    if (medicamentos.docs.length > 0) {
                        medicamentos.docs.map((doc) => {
                            let medicamento = MedicamentoModel.FromDB(doc.id, doc.data(),)
                            medicamentosList.push(medicamento);
                        });
                        Cripto.EncryptedStorage("medicamentos", JSON.stringify(medicamentosList));
                        dispatch(medicamentosListar(medicamentosList));
                    } else {
                        console.log("sem medicamentos")
                        dispatch(medicamentosListar('sem medicamentos'));
                    }

                })
            //return Cripto.DecryptedStorage('atendimento_documentos');
        } else {
            let medicamentosList = [];
            firebase
                .firestore()
                .collection("pacientes")
                .doc(idTitular)
                .collection("dependentes")
                .doc(idCliente)
                .collection("medicamentos")
                .get()
                .then((medicamentos) => {
                    console.log(medicamentos.docs.length)
                    if (medicamentos.docs.length > 0) {
                        medicamentos.docs.map((doc) => {
                            console.log(doc.data())
                            let medicamento = MedicamentoModel.FromDB(doc.id, doc.data(),)
                            medicamentosList.push(medicamento);
                        });
                        Cripto.EncryptedStorage("medicamentos", JSON.stringify(medicamentosList));
                        console.log(medicamentosList)
                        dispatch(medicamentosListar(medicamentosList));
                        console.log(medicamentos.docs.length)
                    } else {
                        console.log("sem medicamentos")

                        dispatch(medicamentosListar('sem medicamentos'));
                    }
                })
        }
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getMedicamentos', ex.message, "")
    }
};