
class AlergiaModel{
    constructor() {
        
    }
    
    static Clear(){  
        let result = {
            disponivel: true,
            idPaciente: '',
            isDependente: '',
            nome: '',
            observacao: '',
            tipo: '',
            url: '',
        }
        return result;
    }

    static ToDB(alergia){
        let result = {
            idPaciente: alergia.idPaciente,
            isDependente: alergia.isDependente,
            nome: alergia.nome,
            tipo: alergia.tipo,
            observacao: alergia.observacao,
            url: alergia.url,
            disponivel: alergia.disponivel,
        }
        return result;
    }

    static FromDB(id, docData){ 
        //console.log(docData)
        let alergia = {
            id : id,
            idPaciente: docData.idPaciente,
            isDependente: docData.isDependente,
            nome: docData.nome,
            tipo: docData.tipo,
            url: docData.url,
            observacao: docData.observacao,
            disponivel: docData.disponivel === undefined ? false : docData.disponivel
        }
        return alergia;
    }
    
}
export default AlergiaModel;