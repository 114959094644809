import firebase from "../config/firebase";
import Log from '../components/log'
import "firebase/auth";
import "firebase/firestore";
import Cripto from '../components/cripto'
import ExameModel from '../model/alergiaModel';
import { store } from '../../src/index.js'
import { storage } from "../config/firebasestorage";
import { replaceEspace } from "../utils/utils";

export const EXAMES_LISTAR = "EXAMES_LISTAR";
export const examesListar = (exames) => ({
    type: EXAMES_LISTAR,
    exames: exames,
});

export const EXAME_SET_CURRENT = 'EXAME_SET_CURRENT';
const exameSetCurrent = exame => ({
    type: EXAME_SET_CURRENT,
    exame
});

export const EXAME_SET_FIELD = 'EXAME_SET_FIELD';
export const exameSetField = (field, value) => {
    return {
        type: EXAME_SET_FIELD,
        field,
        value,
    }
}


export const exameSetAtual = (id) => dispatch => {
    if (id == null) {
        var exame = ExameModel.Clear()
        dispatch(exameSetCurrent(exame));
    } else {
        var currentValue = store.getState().exames;
        let exame = '';
        currentValue.map((item) => {
            if (item.id === id) {
                exame = item;
            }
        });
        dispatch(exameSetCurrent(exame));
    }
}

export const ExcluirExame = async (idCliente, idItem, callbackFinishedDelete) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('exames')
                    .doc(idItem)
                    .delete()
                    .then((success) => {
                        callbackFinishedDelete();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('exames')
                    .doc(idItem)
                    .delete()
                    .then((success) => {
                        callbackFinishedDelete();
                        resolve('sucesso');
                    }).catch((error) => {
                        reject('error')
                    })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'ExcluirExames', ex.message, "")
    }
}

export const cadastrarExame = async (idCliente, pdf, callbackFinishedUpload) => {
    try {
        var exame = ExameModel.ToDB(store.getState().exame);
            exame.nome = replaceEspace(exame.nome)
            exame.observacao = replaceEspace(exame.observacao)
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        return await new Promise((resolve, reject) => {
            if (pdf.length > 0) {
                const uploadMedicamento = storage.ref(`exames/${idTitular}/${pdf[0].name}`)
                    .put(pdf[0]);
                uploadMedicamento.then((success) => {
                    storage.ref(`exames/${idTitular}/`)
                        .child(pdf[0].name)
                        .getDownloadURL()
                        .then(async (url) => {
                            ///setar a url
                            var tipo = 0;
                            if (pdf[0].name.toString().toLowerCase().includes("pdf")) {
                                tipo = 1
                            }
                            exame.url = url;
                            if (idTitular == idCliente) {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('exames')
                                    .add(exame)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            } else {
                                await firebase
                                    .firestore()
                                    .collection('pacientes')
                                    .doc(idTitular)
                                    .collection('dependentes')
                                    .doc(idCliente)
                                    .collection('exames')
                                    .add(exame)
                                callbackFinishedUpload();
                                resolve('sucesso');
                            }
                        }).catch((error) => {
                            console.log('cadastrarExameComAnexo ERROR1: ' + error.message);
                            Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarExameComAnexo', error.message, "")
                            reject("error");
                        });
                })
            }
        })
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'cadastrarExameComAnexo', ex.message, "")
    }
}


export const atualizarExame = async (idCliente, idItem, callbackFinishedUpload) => {
    try {
        return await new Promise((resolve, reject) => {
            var exame = ExameModel.ToDB(store.getState().exame);
            exame.nome = replaceEspace(exame.nome)
            exame.observacao = replaceEspace(exame.observacao)
            var idTitular = Cripto.DecryptedStorage('idPaciente');
            if (idTitular == idCliente) {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idCliente)
                    .collection('exames')
                    .doc(idItem)
                    .update(
                        exame
                    ).then((success) => {
                        resolve('sucesso')
                        callbackFinishedUpload()
                    }
                    ).catch((error) => {
                        reject('error')
                    });
            } else {
                firebase
                    .firestore()
                    .collection('pacientes')
                    .doc(idTitular)
                    .collection('dependentes')
                    .doc(idCliente)
                    .collection('exames')
                    .doc(idItem)
                    .update(
                        ExameModel.ToDB(exame)
                    ).then((success) => {
                        resolve('sucesso')
                        callbackFinishedUpload()
                    }
                    ).catch((error) => {
                        reject('error')
                    });;
            }
        });
    } catch (ex) {
        console.log(ex)
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atualizarExame', ex.message, "")
    }
}

export const getExamesCliente = (idCliente) => (dispatch) => {
    try {
        var idTitular = Cripto.DecryptedStorage('idPaciente');
        let examesList = [];
        if (idTitular == idCliente) {
            firebase
                .firestore()
                .collection("pacientes")
                .doc(idCliente)
                .collection("exames")
                .get()
                .then((exames) => {
                    if (exames.docs.length > 0) {

                        exames.docs.map((doc) => {
                            let exame = ExameModel.FromDB(doc.id, doc.data(),)
                            examesList.push(exame);
                        });
                        Cripto.EncryptedStorage("exames", JSON.stringify(examesList));
                        dispatch(examesListar(examesList));
                    } else {
                        dispatch(examesListar("sem exames"));
                    }
                })
            //return Cripto.DecryptedStorage('atendimento_documentos');
        } else {
            firebase
                .firestore()
                .collection("pacientes")
                .doc(idTitular)
                .collection("dependentes")
                .doc(idCliente)
                .collection("exames")
                .get()
                .then((exames) => {
                    if (exames.docs.length > 0) {
                        exames.docs.map((doc) => {
                            let exame = ExameModel.FromDB(doc.id, doc.data(),)
                            examesList.push(exame);
                        });
                        Cripto.EncryptedStorage("exames", JSON.stringify(examesList));
                        dispatch(examesListar(examesList));
                    } else {
                        dispatch(examesListar("sem exames"));
                    }
                })
        }
    } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getExames', ex.message, "")
    }
};