import Cripto from "../components/cripto";

class SolicitarConsulta {
    constructor() {
    };
    voidConstructor() {
        var solicitarConsultaModel = {
            idPaciente: Cripto.DecryptedStorage('idPaciente'),
            isDependente: false,
            idDependente: "",
            lat: 0,
            long: 0,
            motivoConsulta: "",
            idParceiro: "",
            nomeParceiro: "",
            isClinicaDigital: false,
            idClinicaDigital: "",
            idTecEnfermagem: "",
            nomeTecEnfermagem: "",
            fotoTecEnfermagem: "",
            colorTecEnfermagem: "",
            corenTecEnfermagem: "",
            nomeClinicaDigital: "",
            dataIniFilaTriagem: 0,
            tipo: "WEB"
        };
        return solicitarConsultaModel;
    }
    completeConstructor(solicitarConsulta) {
        var solicitarConsultaModel = {
            idPaciente: solicitarConsulta.idPaciente,
            isDependente: solicitarConsulta.isDependente,
            idDependente: solicitarConsulta.idDependente,
            lat: solicitarConsulta.lat,
            long: solicitarConsulta.long,
            motivoConsulta: solicitarConsulta.motivoConsulta,
            idParceiro: solicitarConsulta.idParceiro,
            nomeParceiro: solicitarConsulta.nomeParceiro,
            isClinicaDigital: solicitarConsulta.isClinicaDigital,
            idClinicaDigital: solicitarConsulta.idClinicaDigital,
            idTecEnfermagem: solicitarConsulta.idTecEnfermagem,
            nomeTecEnfermagem: solicitarConsulta.nomeTecEnfermagem,
            fotoTecEnfermagem: solicitarConsulta.fotoTecEnfermagem,
            corenTecEnfermagem: solicitarConsulta.fotoTecEnfermagem,
            colorTecEnfermagem: solicitarConsulta.colorTecEnfermagem,
            nomeClinicaDigital: solicitarConsulta.nomeClinicaDigital,
            dataIniFilaTriagem: solicitarConsulta.dataIniFilaTriagem,
            tipo: solicitarConsulta.tipo
        };
        return solicitarConsultaModel;
    }
};

export default SolicitarConsulta;