import firebase from '../config/firebase';
import Cripto from '../components/cripto'
import 'firebase/auth';
import 'firebase/firestore';
import { storage } from "../config/firebasestorage";
import Log from '../components/log'
import { docData } from "rxfire/firestore";
import { getIdToken, request } from '.';

export const ATENDIMENTOS_LISTAR_CLIENTES = "ATENDIMENTOS_LISTAR_CLIENTES";
const atendimentosListarConsultarCliente = (atendimento) => ({
    type: ATENDIMENTOS_LISTAR_CLIENTES,
    atendimentos: atendimento,
});

export const atendimentoAceitaCall = async (
    idConsulta,
    idPaciente,
    idMedico,
) => {
    try {
        return await new Promise(async (resolve, reject) => {
            var idToken = await getIdToken();

            request.post(`/paciente/${idPaciente}/atendimento/${idConsulta}/medico/${idMedico}/atenderchamada`, {}, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
                .then(result => {
                    try {
                        resolve();
                    } catch (e) {
                        reject();

                    }
                }
                )
                .catch(erro => {
                    reject();
                })
        })
    }
    catch (ex) {
        console.log('atendimentoFinalizaCallSincrona2', ex.message)
    }
};

export const triagemAceitaCall = async (
    idConsulta,
    idPaciente,
    idEnfermeiro,
) => {
    try {
        return await new Promise(async (resolve, reject) => {
            var idToken = await getIdToken();
            request.post(`/paciente/${idPaciente}/atendimento/${idConsulta}/enfermeiro/${idEnfermeiro}/atenderchamada`, {}, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
                .then(result => {
                    try {
                        console.log(result)
                        if (result.data.statusOk) {
                            resolve();
                        } else {
                            reject()
                        }
                    } catch (e) {
                        reject();
                    }
                }
                )
                .catch(erro => {
                    reject();
                })
        })
    }
    catch (ex) {
        console.log('atendimentoFinalizaCallSincrona2', ex.message)
    }
};

export const atendimentoCancelar = (idPaciente, that) => {
    try {
        var func = firebase
        firebase.app().functions("southamerica-east1")
            .httpsCallable("fila-cancelar");
        return func({
            pacienteId: idPaciente,
        })
            .then(() => {
                that.callbackCancelarFila();
            })
            .catch((e) => {
                console.log('atendimentoCancelar ERRO 1: ', e.message)
            });
    }
    catch (ex) {
        console.log('atendimentoCancelar ERRO 2: ', ex.message)
    }
};


export const listaAtendimentosCliente = (idPaciente) => {
    try {

        return async (dispatch) => {
            var snapshot = await firebase.firestore().collection('pacientes').doc(idPaciente).collection('atendimentos')
                .orderBy("dataIniTriagem", "desc")
                .limit(50)
                .get();
            var itens = [];
            var i = 0;
            if (snapshot.docs.length > 0) {
                snapshot.docs.map(async doc => {
                    let tt = { ...doc.data(), id: doc.id, }
                    itens.push(tt);
                });
                dispatch(atendimentosListarConsultarCliente(itens));
            } else {
                dispatch(atendimentosListarConsultarCliente('sem consultas'));
            }
        }

    }
    catch (ex) {
        console.log('dependentesLista ERROR')
    }
};


export const atendimentoFinalizaCallSincronaPaciente = async (
    idConsulta,
    idPaciente,
    idMedico,
) => {
    try {
        var idToken = await getIdToken();

        request.post(`/paciente/${idPaciente}/atendimento/${idConsulta}/medico/${idMedico}/finalizarchamada`, {}, {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        })
            .then(() => {
                return true;
            })
            .catch((e) => {
                console.log('atendimentoFinalizaCallSincrona1', e.message)
            });
    }
    catch (ex) {
        console.log('atendimentoFinalizaCallSincrona2', ex.message)
    }
};
export const triagemFinalizaCallSincronaPaciente = async (
    idConsulta,
    idPaciente,
    idEnfermeiro,
) => {
    try {
        var idToken = await getIdToken();
        request.post(`/paciente/${idPaciente}/atendimento/${idConsulta}/enfermeiro/${idEnfermeiro}/finalizarchamada`, {}, {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        })
            .then((resp) => {
                if (resp.data.statusOk) {
                    return;
                } else {
                    return;
                }
            })
            .catch((e) => {
                console.log('atendimentoFinalizaCallSincrona1', e.message)
            });
    }
    catch (ex) {
        console.log('atendimentoFinalizaCallSincrona2', ex.message)
    }
};
export const ATENDIMENTO_SET_CURRENT = 'ATENDIMENTO_SET_CURRENT';
export const atendimentoSetCurrent = (value) => {
    return {
        type: ATENDIMENTO_SET_CURRENT,
        value,
    }
}

export const atendimentoObserverCollectionConsultas = (idPaciente, idAtendimento) => {
    try {
        const docRef = firebase.firestore().collection("pacientes").doc(idPaciente).collection('atendimentos').doc(idAtendimento);
        return async dispatch => {
            docData(docRef).subscribe(async (doc) => {
                if (doc.status != undefined) {
                    if (doc.status != null) {
                        dispatch(atendimentoSetCurrent(doc));
                    }
                } else {
                    dispatch(atendimentoSetCurrent({}));
                }
            }
            );
        }
    }
    catch (ex) {
        console.log(ex);
        console.log(Cripto.DecryptedStorage('uid'));
        console.log(Cripto.DecryptedStorage('idAtendimento'));
        console.log('atendimentoObserverCollectionConsultas ERROR');
    }
};


export const setStatusPaciente = async (idPaciente) => {
    await firebase.firestore().collection('pacientes').doc(idPaciente).update({
        statusPaciente: 0,
        idAtendimento: ''
    }).then(success => {
        return true
    }
    ).catch(error => {
        return false
    });
}
