import { timestamp } from "rxjs/operators";
import Cripto from "../components/cripto";

export const ValidaCPF = (strCPF) => {
    var Soma;
    var Resto;
    Soma = 0;
    var testeCpfReplace = strCPF.replace('.', '');
    testeCpfReplace = testeCpfReplace.replace('-', '');
    testeCpfReplace = testeCpfReplace.replace('.', '');
    if (testeCpfReplace == "00000000000") return false;

    for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(testeCpfReplace.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(testeCpfReplace.substring(9, 10))) return false;

    Soma = 0;
    for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(testeCpfReplace.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(testeCpfReplace.substring(10, 11))) return false;
    return true;
}

export const validaCNPJ = (cnpj) => {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;

}




export const getDateDDMMYYYYHHMMFromTimeStamp = (timeStamp) => {
    var idioma = Cripto.DecryptedStorage('idioma');
    if(timeStamp === '' || timeStamp === undefined){
        return '';
    }
    if (idioma === "pt") {
        var date = new Date(timeStamp * 1);
        // Hours part from the timestamp

        var dia = "0";
        if (date.getDate() < 10) {
            dia = "0" + date.getDate();
        } else {
            dia = date.getDate();
        }

        var mes = "0";
        if (date.getMonth() + 1 < 10) {
            mes = "0" + (date.getMonth() + 1);
        } else {
            mes = (date.getMonth() + 1);
        }

        var hours = "0";
        if (date.getHours() < 10) {
            hours = "0" + date.getHours();
        } else {
            hours = date.getHours();
        }
        var minutes = "0";
        if (date.getMinutes() < 10) {

            minutes = "0" + date.getMinutes();
        } else {
            minutes = date.getMinutes();
        }
        // Seconds part from the timestamp
        var seconds = "0"
        if (date.getSeconds() < 10) {
            seconds = "0" + date.getSeconds();
        } else {
            seconds = date.getSeconds();
        }
        var formattedTime = dia + "/" + mes + "/" + date.getFullYear() + " " + hours + ":" + minutes;
        return formattedTime;
    } else {
        var date = new Date(timeStamp);
        // Hours part from the timestamp

        var dia = "0";
        if (date.getDate() < 10) {
            dia = "0" + date.getDate();
        } else {
            dia = date.getDate();
        }

        var mes = "0";
        if (date.getMonth() + 1 < 10) {
            mes = "0" + (date.getMonth() + 1);
        } else {
            mes = (date.getMonth() + 1);
        }

        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        var formattedTime = mes + "/" + dia + "/" + date.getFullYear() + " " + strTime;
        return formattedTime;
    }
}


export const getStrTempoEsperaFromTimeStamp = (timeStamp) => {

    var daysDifference = Math.floor(timeStamp / 1000 / 60 / 60 / 24);
    timeStamp -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(timeStamp / 1000 / 60 / 60);
    timeStamp -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(timeStamp / 1000 / 60);
    timeStamp -= minutesDifference * 1000 * 60

    var secondsDifference = Math.floor(timeStamp / 1000);
    if (daysDifference > 0) {
        return daysDifference + " dias " + hoursDifference + " horas " + minutesDifference + " min";
    } else if (hoursDifference > 0) {
        return hoursDifference + " horas " + minutesDifference + " min"
    } else if (minutesDifference > 0) {
        return minutesDifference + " min " + secondsDifference + " seg";
    } else if (secondsDifference > 0) {
        return secondsDifference + " segundos";
    }
    else if (secondsDifference > 0) {
        return "0 segundo!";
    }

    return (hoursDifference * 60) + minutesDifference;

}


export const getSegundosFromTimeStamp = (timeStamp) => {

    var seconds = new Date(timeStamp).getTime() / 1000;
    return seconds;
}


export const getDateDDMMYYYYFromTimeStamp = (timeStamp) => {
    var date = new Date(timeStamp * 1);


    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();
    var formattedTime = date.getDate() + "/" + getMonth(date) + "/" + date.getFullYear()

    return formattedTime;
}

function getMonth(date) {
    var mes = date.getMonth() + 1;
    var mesFormatted = mes > 10 ? mes :  '0' + mes;
    return mesFormatted;
}

export const getTimeStampFromDate = (date) => {
    var myDate = date.split("/");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    var d = new Date(newDate).getTime();

    return d;
}

export const CalculaIdade = (dataNasc) => {
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var anoNascParts = dataNasc.split('/');
    var diaNasc = anoNascParts[0];
    var mesNasc = anoNascParts[1];
    var anoNasc = anoNascParts[2];
    var idade = anoAtual - anoNasc;
    var mesAtual = dataAtual.getMonth() + 1;
    //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
    if (mesAtual < mesNasc) {
        idade--;
    } else {
        //Se estiver no mes do nascimento, verificar o dia
        if (mesAtual == mesNasc) {
            if (new Date().getDate() < diaNasc) {
                //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
                idade--;
            }
        }
    }
    return idade;
}
export const ValidaNome = (nome) => {
    return true;
    var nomeSplit = nome.split(" ");
    if (nome.match(/^(?![ ])(?!.*[ ]{2})((?:e|da|do|das|dos|de|d'|D'|la|las|el|los)\s*?|(?:[A-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'][^\s]*\s*?)(?!.*[ ]$))+$/)) {
        if (nomeSplit[1] != undefined && nomeSplit[1].length >= 2) {
            if (nomeSplit[1].length >= 4) {
                return true;
            } else if (nomeSplit[2] != undefined && nomeSplit[2].length >= 4) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const getDateFromTimeStamp = (timeStamp) => {

    var date = new Date((timeStamp));
    return date;
    // Hours part from the timestamp 
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    var formattedTime = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes();

    return formattedTime;
}
export const validaCamposAlpha = (texto) => {
    if(texto.match(/^(\s)+$/g)){
        return false;
    }
    if(texto.match(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/g)){
        return true
    }
    return false
}