export function removePaddingPContent() {
    var pcodedContent = document.getElementsByClassName('pcoded-content');
    if (pcodedContent.length > 0) {
        pcodedContent[0].style.padding = '0px';
    };
};
export function addPaddingPContent() {
    var pcodedContent = document.getElementsByClassName('pcoded-content');
    if (pcodedContent.length > 0) {
        pcodedContent[0].style.padding = '30px';
    };
};


export const replaceEspace = (value) => {
    try {
        value = value.replace(/(^( )+)|( )+$|( {2,})/g, ' ');
        return value;
    } catch (error) {
        console.log(error)
        return ""
    }
}

export const validaCampoTextArea = (value) => {
    try {
        if (value !== undefined && value.length > 0) {
            if(value.match(/[ \S]*[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+[ \S]*/g)){
                return true;
            }
            return false
        }
        }catch (error) {
            console.log(error)
            return false;
        }
    }

export const validaCampo = (value) => {
    try {
        if (value !== undefined && value.length > 0) {
            var caracterAtual = value.charCodeAt(value.length - 1);
            var caracterAnterior = value.charCodeAt(value.length - 2);
            if (value.match(/^(\s)+$/m) || value.match(/\s{2,}(\w+)?(\s{2,})?/m)) {
                return false
            }
            if (caracterAtual === 32 && caracterAnterior === 32) {
                return false;
            }
            return true;
    } }
    catch (error) {
        return false;
    }
    }

