import { getIdToken, request } from ".";
import { store } from "..";
import Cripto from "../components/cripto";
import SolicitarConsulta from "../model/SolicitarConsulta";
import { replaceEspace } from "../utils/utils";

export const SOLICITAR_ATENDIMENTO_SET_FIELD = 'SOLICITAR_ATENDIMENTO_SET_FIELD';
export const solicitarAtendimentoSetField = (field, value) => {
    return {
        type: SOLICITAR_ATENDIMENTO_SET_FIELD,
        field,
        value,
    }
}
export const SOLICITAR_ATENDIMENTO_SET_CURRENT = 'SOLICITAR_ATENDIMENTO_SET_CURRENT';
export const solicitarAtendimentoSetCurrent = (value) => {
    return {
        type: SOLICITAR_ATENDIMENTO_SET_CURRENT,
        value,
    }
}
export const addFila = async () => {
    var SolicitarConsultaModel = new SolicitarConsulta().completeConstructor(store.getState().solicitarConsulta);
    SolicitarConsultaModel.motivoConsulta = replaceEspace(SolicitarConsultaModel.motivoConsulta);
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.post('/filatriagem', SolicitarConsultaModel, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
                .then(resp => {
                    if (resp.data.statusOk) {
                        Cripto.EncryptedStorage("idConsulta", resp.data.data)
                        resolve()
                    } else {
                        reject('falhaAoSolicitarConsulta');
                    }
                }).catch(error => {
                    reject("falhaAoSolicitarConsulta")
                    console.log(error)
                });
        } catch (error) {
            reject(error)
        }
    })
};

export const cancelarFila = async (idAtendimento) => {
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.delete(`/filatriagem/${idAtendimento}`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
                .then((resp) => {
                    if (resp.data.statusOk) {
                        resolve();
                    } else {
                        reject()
                    }

                })
                .catch((e) => {
                    reject()
                    console.log('atendimentoCancelar ERRO 1: ', e.message)
                });
        } catch (ex) {
            reject()
        }
    });
}