import { USER_LOGIN_SUCCESS, USER_LOGOUT, USER_SET_FIELD, USER_NOTIFICACOES_PACIENTE, USER_SET_FIELD_DADOS,USER_SET_FIELD_CONFIG } from '../actions';
import User from '../model/User';
const INITIAL_STATE = {
	usuarioEmail: '',
	usuarioLogado: 0,
	usuario: new User().voidConstructor(),
	notificacoespaciente: [],
	config: {
		idioma: '',
		layout: '',
		isHistoricoAtendimento: false
	}
}


const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case USER_LOGIN_SUCCESS:
			return action.user;
		case USER_LOGOUT:
			return null;
		case USER_SET_FIELD:
			const newState = { ...state };
			newState[action.field] = action.value;
			return newState;
		case USER_SET_FIELD_DADOS:
			const setFieldUser = { ...state };
			setFieldUser.usuario[action.field] = action.value;
			return setFieldUser;
		case USER_SET_FIELD_CONFIG:
			const newState6 = { ...state };
			newState6.config[action.field] = action.value;
			return newState6;
		case 'TOKEN':
			const newState2 = { ...state };
			newState2.dadosUsuario['token'] = action.value;
			return newState2;
		case USER_NOTIFICACOES_PACIENTE:
			const newState3 = { ...state, notificacoespaciente: action.value };
			return newState3;
		default:
			return state;
	}
}

export default userReducer;