import { getColor } from "../utils/getFoto";

class NewUser {
    constructor() {
    }
    voidConstructor() {
        const newUser =
        {
            cpf: '',
            dataNasc: '',
            nomeMae: '',
            nome: '',
            fotoSelfie: '',
            fotoDocumento: '',
            sexo: '',
            rg: '',
            color: getColor()
        };
        return newUser;
    }
    createConstructor(user) {
        const newUser =
        {
            cpf: user.cpf,
            dataNasc: user.dataNasc,
            nomeMae: user.nomeMae,
            nome: user.nome,
            sexo: user.sexo,
            rg: user.rg,
        };
        return newUser;
    }
}

export default NewUser;